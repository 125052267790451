<template>
  <div>
    <v-card 
      v-if="property" 
      class="rounded-sm white" 
      elevation="10"
    >
      <v-card-text class="">
        <p class="my-0">Advertised by</p>
        <p  v-if="selectedPersonAccount.personType === 'Applicant Tenant'" class="text-h5">{{ property.Agency }}</p>
        <p  v-if="selectedPersonAccount.personType === 'Applicant Buyer'" class="text-h5">{{ property.AgencyName }}</p>
        <v-btn 
          :href="`tel:${agentContactNumber}`"
          class="mt-0 rounded-sm" 
          color="primary"
          dark
          width="100%" 
          large
          depressed
        >
          <v-icon left>mdi-phone</v-icon>
          <span v-if="selectedPersonAccount.personType === 'Applicant Tenant'" class="text-h6 font-weight-bold">{{ property.AgencyPhoneNumber }}</span>
          <span v-if="selectedPersonAccount.personType === 'Applicant Buyer'" class="text-h6 font-weight-bold">{{ property.AgencyNumber }}</span>
        </v-btn>
        <v-btn 
          :href="`mailto:${property.AgencyEmail}`"
          class="mt-3 rounded-sm" 
          color="primary"
          dark
          width="100%" 
          large
          depressed
        >
          <span class="custom-transform-class text-none">{{ property.AgencyEmail }}</span>
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'agencyDetailsCard',
  computed: {
    property() {
      if(this.$store.state.linkedProperties.length) return this.$store.state.linkedProperties[0]
      if(this.$store.state.favouriteProperties.length) return this.$store.state.selectedProperty
      return ''
    },
    selectedPersonAccount() {
      return this.$store.state.selectedPersonAccount
    },
    agentContactNumber() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') return this.property.AgencyPhoneNumber
      return this.property.AgencyNumber
    }
  },

}
</script>

<style>

</style>